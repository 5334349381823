<template>
  <div>
    您还未登录哦～
  </div>
</template>

<script>
export default {
  name: 'NoLogin',
};
</script>

<style lang="scss" scoped>

</style>
